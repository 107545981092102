body {
  position: relative;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

.App-header {
  background-color:#002458 ;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
}


.footer {
  position: absolute;
  width: 100%;
  height: 15vh;
  bottom: 0;
  background-image: url('logo2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #002458;
}

.push {
  height: 25vh;
}

.centered {
  text-align: center;
  margin-top: 20px;
}



